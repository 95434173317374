export default function Heading({ hTag = "h1", children }) {
    const Tag = hTag;
    const classes = {
        "h1": "text-[24px] leading-[32px] lg:text-[28px]  lg:leading-[34px]",
        "h2": "text-[32px] leading-[44px] ",
    }
    return <Tag className={`${classes[hTag]}`}>
        {children}
    </Tag >
}
