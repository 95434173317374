import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Text from '../atoms/Text'
import algoliasearch from 'algoliasearch';
import Link from 'next/link';
import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import React, { Component } from 'react';
import { InstantSearch, Hits, SearchBox, Configure, } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import ImageSet from '../atoms/ImageSet';

const searchClient = algoliasearch(
    'OLW3XJ5QCH',
    '0967c174a85fbccf57f4b6ab47a6f401'
);


function SearchBar({ isSearchBarVisible, toggleSearchBarVisible }) {

    return (

        <Transition.Root show={isSearchBarVisible} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={toggleSearchBarVisible}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-0 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="ais-InstantSearch inline-block align-bottom bg-white rounded-lg px-2 lg:px-4 pt-0 pb-0 lg:pt-5 lg:pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full lg:max-w-3xl lg:w-full sm:p-6" >
                            <InstantSearch indexName="products" searchClient={searchClient}>
                                <Text ptag="copy">
                                    <SearchBox className="text-xl my-2 lg:my-4 block w-full  border-none focus:outline-none " />
                                    <Configure hitsPerPage={4} />
                                    <Hits hitComponent={Hit} />
                                </Text>
                            </InstantSearch>
                        </div >
                    </Transition.Child>
                </div >
            </Dialog>
        </Transition.Root>

    );
}
function Hit(props, toggleSearchBarVisible) {
    const router = useRouter()
    return (
        <a href={`/${props.hit.full_slug}`} onClick={() => router.push(`/${props.hit.full_slug}`)}  >
            <Text pTag="copy">
                <div className="w-full flex  bg-white border-t border-gray-200  py-4  space-x-4">
                    <div className="w-24 lg:w-48 max-h-12 lg:max-h-24 object-contain">
                        <ImageSet image={props.hit.image} />
                    </div>
                    <div className=" text-left w-full flex items-center flex-grow">
                        {props.hit.name ? <>{props.hit.name} <br /></> : null}
                        {props.hit.type ? <>{props.hit.type.name}<br /></> : null}
                        {props.hit.category ? <>{props.hit.category.name}<br /></> : null}
                    </div>
                    <div className="flex whitespace-nowrap items-center"><br /><br />{props.hit.price} €</div>
                </div >
            </Text>
        </a >
    );
}

export default SearchBar;