import * as padding from "../../utils/Padding"
import Text from "../atoms/Text"
import Link from "next/link"

import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

export default function Footer() {

    const [ref, inView, entry] = useInView({
        threshold: 0.2,
    })
    return (
        <div ref={ref} className="w-full h-auto py-24 footer">
            <motion.div
                initial={{ y: "64px" }}
                animate={{ y: "0px" }}
                transition={{
                    type: "easeIn",
                    duration: 1
                }}
            >

                <section>
                    <div className="w-full block lg:flex">
                        <div className="w-full lg:w-1/4">
                            <Text pTag="spacing">
                                <Link href="/shipping-returns"><a>Shipping & returns</a></Link><br />
                                <Link href="/payment-methods"><a>Payment methods</a></Link><br /><br />
                                <Link href="/terms-of-service"><a>Terms of service</a></Link><br />
                                <Link href="/privacy"><a>Privacy</a></Link>
                                <br />
                                <a href="mailto:info@seefried.club">Contact</a><br />
                            </Text>
                        </div>
                        <div className="w-full lg:w-1/4 pt-4 lg:pt-0">
                            {/* <Text pTag="spacing">
                                Newsletter
                                <div className="bg-transparent focus:ring-indigo-500 focus:border-indigo-500 w-full  border-b ">
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="you@example.com"
                                    />
                                </div>
                            </Text> 
                            <br /><br />*/}
                            <Text pTag="spacing">
                                <a href="https://www.instagram.com/seefried.club/">
                                    Instagram
                                </a>
                            </Text><br />
                            {/* <Text pTag="info">Wir versenden ab einem Warenwert von 80 Euro versand kostenfrei innerhalb Deutschlands. Ab einem Warenwert von 180 Euro innerhalb der EU.</Text> */}
                        </div>
                    </div>
                </section>
            </motion.div>
        </div>
    )
}