import { motion } from "framer-motion";

export default function HeroLogo({ scrollPosition }) {

    const opacity = scrollPosition * 10 - 0.1
    const pathLength = scrollPosition * 3
    const offset = ((scrollPosition * 100) + "%")
    {
        return (
            <motion.div
                initial={{
                    y: offset,
                    opacity: 0,
                }}
                animate={{
                    y: offset,
                    opacity: opacity,

                }}
                className="w-full items-start flex "
            >

                <svg version="1.1" id="Ebene_1"
                    width="full" height="full" viewBox="0 0 36.6 36.5"
                >
                    <motion.path
                        stroke="#fff"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: pathLength }}
                        fill="none"
                        strokeWidth="0.15"
                        id="circle1" d="M2.299999999999999,18.3a7.9,7.9 0 1,0 15.8,0a7.9,7.9 0 1,0 -15.8,0" />
                    <motion.path
                        stroke="#fff"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: pathLength }}
                        fill="none"
                        strokeWidth="0.15"
                        id="circle2" d="M2.3000000000000007,18.3a16,16 0 1,0 32,0a16,16 0 1,0 -32,0" />
                    <motion.path
                        stroke="#fff"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: pathLength }}
                        fill="none"
                        strokeWidth="0.15"
                        id="curve" d="M18.3,6.4c6.6,0,11.8,5.3,11.8,11.9c0,4.9-3.1,9.4-7.7,11.1" />
                    <motion.path
                        stroke="#fff"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: pathLength }}
                        fill="none"
                        strokeWidth="0.15"
                        id="line" d="M18.3 2.4, L18.3 34.15" />
                </svg>

            </motion.div >
        )
    }
}