export const paddingY = "py-8 lg:py-16 xl:py-24"
export const paddingYQuarter = "py-2 lg:py-4 xl:py-6"
export const paddingYHalf = "py-4 lg:py-8 xl:py-12"
export const paddingYDouble = "py-16 lg:py-32 xl:py-48"

export const paddingTop = "pt-8 lg:pt-16 xl:pt-24"
export const paddingTopHalf = "pt-4 lg:pt-8 xl:pt-12"
export const paddingBottom = "pb-8 lg:pb-16 xl:pb-24"
export const paddingBottomHalf = "pb-4 lg:pb-8 xl:pb-12"
export const paddingBottomDouble = "pb-16 lg:pb-32 xl:pb-48"

export const paddingRight = "pr-4 lg:pr-12 xl:pr-16"
export const paddingLeft = "pr-4 lg:pr-12 xl:pr-16"

export const spacingX = "space-x-4 lg:space-x-8 xl:space-x-12"
export const spacingXOrder = "gap-8 lg:gap-0 lg:space-x-8 xl:space-x-12"

export const gap = "space-y-6 lg:space-y-0 lg:gap-6 "