import ImageSet from "../atoms/ImageSet"
import HeroLogo from "../atoms/HeroLogo"
import { useEffect, useState } from "react"
import { motion, framer } from "framer-motion"

export default function HeroImage({ blok }) {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset / window.innerHeight;
        setScrollPosition(Math.round(position * 100) / 100);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <a href="#home">

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    type: "easeIn",
                    duration: 1
                }}
                className="w-full h-[calc(100vh-62px)] z-[4]  bg-black flex items-center  justify-center relative"
            >
                <div className="absolute z-[4]  " >
                    <HeroLogo scrollPosition={scrollPosition} />
                </div>
                <ImageSet css="object-cover h-screen" image={blok.hero} />
            </motion.div>

        </a>
    )
}