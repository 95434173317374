import Text from "../atoms/Text"
import Heading from "../atoms/Heading"
import * as Padding from "../../utils/Padding"
import Link from "next/link"
import Footer from "./Footer"
import { motion } from "framer-motion"

export default function NavigationMobile({ isNavigationMobile, toggleNavigationMobile }) {
    return (
        <div className="bg-white  w-full h-auto z-20  inset-0">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isNavigationMobile ? 1 : 0 }}
                transition={{
                    type: "easeIn",
                    duration: 0.5,
                }}
            >
                <div className={`relative ${Padding.paddingYHalf}`} onClick={toggleNavigationMobile}>
                    <section>
                        <div className="" >
                            <Heading hTag="h2">
                                <Link href="/">
                                    <a>
                                        Home
                                    </a>
                                </Link>
                                <br />
                                <div className="pl-6">
                                    <Link href="/shop">
                                        <a>
                                            Shop
                                        </a>
                                    </Link><br />
                                    <Link href="/infos">
                                        <a>
                                            Infos
                                        </a>
                                    </Link><br />
                                    <Link href="/journal">
                                        <a>
                                            Journal
                                        </a>
                                    </Link><br />
                                </div>
                            </Heading>
                        </div>
                    </section>
                    <Footer />
                </div>
            </motion.div>
        </div>
    )
}