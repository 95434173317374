import { useRouter } from "next/router";
import { motion, AnimatePresence } from "framer-motion";
import NextHead from '../components/particles/NextHead';
import Navigation from "../components/organisms/Navigation";
import Footer from "../components/organisms/Footer";


import 'swiper/css'
import '../styles/globals.css'
import '../styles/tailwind.css'
import HeroImage from "../components/molecules/HeroImage";
import Overlay from "../components/molecules/Overlay";



function MyApp({ Component, pageProps }) {
  const router = useRouter();

  return (
    <>

      <NextHead />
      {pageProps.story && pageProps.story.name === "Home" ? (<><HeroImage blok={pageProps.story.content} /></>) : null}
      <Navigation />

      <div hidden id="snipcart" data-api-key="MzFiNDNlY2MtMWVlZC00YjZiLTgzOGYtMmM4ZmYwOWFlZjIyNjM3NDMxMzU0NzUxMjg3NTQ3" data-config-modal-style="side" data-autopop="false" />
      <AnimatePresence exitBeforeEnter on >
        <Component key={router.route} {...pageProps} />
      </AnimatePresence>
      <Footer />

    </>
  )
}

export default MyApp
