import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { transformImage } from "../../utils/transformImage"

export default function ImageSet({ image, css, zoom }) {
    return (
        <>{!zoom ? (
            <img className={`object-cover h-auto md:h-full w-full  ${css}`}
                alt={image.alt ? image.alt : null}
                src={transformImage(image.filename, "0x1024", "jpeg")}
                srcSet={`${transformImage(image.filename, "0x400", "90")} 400w, 
                         ${transformImage(image.filename, "0x640", "90")} 640w,
                         ${transformImage(image.filename, "0x768", "90")} 768w,
                         ${transformImage(image.filename, "0x1024", "90")} 1024w,
                         ${transformImage(image.filename, "0x1440", "90")} 1440w, />
                         ${transformImage(image.filename, "0x2160", "90")} 2160w`} />
        ) : (
            <InnerImageZoom
                className={`object-cover h-auto w-full  ${css}`}
                hideHint
                hideCloseButton
                zoomType="hover"
                zoomPreload
                alt={image.alt ? image.alt : null}
                zoomSrc={transformImage(image.filename, "0x2560", "80")}
                src={transformImage(image.filename, "0x1024", "90")}
                srcSet={`${transformImage(image.filename, "0x400", "90")} 400w, 
                         ${transformImage(image.filename, "0x640", "90")} 640w,
                         ${transformImage(image.filename, "0x768", "90")} 768w,
                         ${transformImage(image.filename, "0x1024", "90")} 1024w,
                         ${transformImage(image.filename, "0x1440", "90")} 1440w`}
            />
        )}
        </>
    )
}