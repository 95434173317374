import Head from 'next/head'

export default function NextHead() {
    return (
        <Head>
            <meta charSet="UTF-8" />
            <title>Seefried</title>
            <meta name="title" content="Seefried" />
            <meta name="description" content="Genuine material. Timeless shapes. Ceramics with which the raw beauty of nature can be experienced anew every day. Piece by piece." />
            <meta name="og:title" content="Seefried" />
            <meta name="og:description" content="Genuine material. Timeless shapes. Ceramics with which the raw beauty of nature can be experienced anew every day. Piece by piece." />
            <meta name="twitter:title" content="Seefried" />
            <meta name="twitter:description" content="Genuine material. Timeless shapes. Ceramics with which the raw beauty of nature can be experienced anew every day. Piece by piece." />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover" />


            <link rel="preconnect" href="https://app.snipcart.com" />
            <link rel="preconnect" href="https://cdn.snipcart.com" />

            <script src="https://cdn.snipcart.com/themes/v3.2.1/default/snipcart.js" data-api-key="MzFiNDNlY2MtMWVlZC00YjZiLTgzOGYtMmM4ZmYwOWFlZjIyNjM3NDMxMzU0NzUxMjg3NTQ3" data-autopop="false" />
            <script src="//cdn.iubenda.com/cs/iubenda_cs.js" charSet="UTF-8" async />

            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="msapplication-config" content="/icons/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />

            <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
            <link rel="manifest" href="/icons/site.webmanifest" />
            <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
            <link rel="shortcut icon" href="/icons/favicon.ico" />

            <script dangerouslySetInnerHTML={{
                __html: `var _iub = _iub || [];
_iub.csConfiguration = {"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"de","siteId":2378478,"cookiePolicyId":32683154, "banner":{ "applyStyles":false,"acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#010101","acceptButtonCaptionColor":"white","customizeButtonColor":"#212121","customizeButtonCaptionColor":"white","rejectButtonColor":"#010101","rejectButtonCaptionColor":"white","closeButtonDisplay":false,"position":"top","textColor":"#010101","backgroundColor":"#ffffff","fontSize":"14px","fontSizeBody":"14px" }};
                        ` }} />
        </Head>
    )
}