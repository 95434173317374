import React from "react"

export default function Text({ pTag = "small", children }) {
    const Tag = pTag;
    const classes = {
        "copy": "text-[18px] leading-[28px] lg:text-[22px] lg:leading-[30px]",
        "small": "text-[16px] leading-[22px] lg:text-[18px] lg:leading-[24px]",
        "para": "text-[16px] leading-[24px] lg:text-[18px] lg:leading-[28px]",
        "info": "text-[14px] leading-[22px] ",
        "regular": "text-[16px] leading-[24px] ",
        "spacing": "text-[18px] leading-[28px] ",
    }
    return (
        <div className={`${classes[pTag]}`}>
            {children}
        </div>
    )
}




