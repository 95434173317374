import * as padding from "../../utils/Padding"
import Text from "../atoms/Text"
import Link from "next/link"
import { useRouter } from "next/dist/client/router"
import { List, X } from "phosphor-react";
import { useState } from "react";
import NavigationMobile from "./NavigationMobile";
import SearchBar from "../molecules/SearchBar";
import useSnipcartCount from "../../utils/useSnipcartCount";


export default function Navigation() {

    const { cart } = useSnipcartCount();
    const cartHasItems = cart.items.count;

    const router = useRouter();
    const [isNavigationMobile, setNavigationMobile] = useState(false);
    const [isSearchBarVisible, setSearchBarVisible] = useState(false);

    function toggleNavigationMobile() {
        setNavigationMobile(!isNavigationMobile)
    }
    function toggleSearchBarVisible() {
        setSearchBarVisible(!isSearchBarVisible)
    }

    return (
        <>
            <div className={`w-full bg-white z-10 py-4 navigation select-none top-0 ${isNavigationMobile ? "fixed min-h-screen" : "sticky"}`}>
                <section>
                    <div className="flex justify-between w-full navigation  ">
                        <div onClick={toggleNavigationMobile} className="block lg:hidden">
                            {isNavigationMobile ? <X size={28} /> : <List size={28} />}
                        </div>
                        <div className="w-full hidden lg:flex ">
                            <Link href="/#home">
                                <a>
                                    <Text>
                                        Seefried
                                    </Text>
                                </a>
                            </Link>
                        </div>
                        <div className="w-full space-x-24 hidden lg:flex">
                            <div className="">
                                <Link href="/shop">
                                    <a>
                                        <Text>
                                            Shop
                                        </Text>
                                    </a>
                                </Link>
                            </div>
                            <div className="">
                                <Link href="/infos">
                                    <a>
                                        <Text>
                                            Infos
                                        </Text>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full justify-center hidden lg:flex pl-20">
                            <Link href="/journal">
                                <a>
                                    <Text>
                                        Journal
                                    </Text>
                                </a>
                            </Link>
                        </div>
                        <div onClick={toggleSearchBarVisible} className="w-full justify-end hidden lg:flex cursor-pointer">
                            <Text>
                                <a>
                                    Search
                                </a>
                            </Text>
                        </div>

                        <div className="snipcart-checkout w-full flex items-center justify-end space-x-4 cursor-pointer " aria-label="Cart">
                            <span className={`${isNavigationMobile ? "hidden" : "block pb-[4px]"}`}>
                                <Text>
                                    Cart
                                </Text>
                            </span>
                            <div className="rounded-full border border-black hover:bg-black hover:text-white duration-300 hover px-2  pb-[2px] ">
                                {cartHasItems ? (<span className="snipcart-items-count">{cartHasItems}</span>) : (<span className="snipcart-items-count">0</span>)}
                            </div>
                        </div>
                    </div>
                </section>
                <div className={` ${isNavigationMobile ? "block  inset-0 z-5" : "hidden"}`}>
                    <NavigationMobile isNavigationMobile={isNavigationMobile} toggleNavigationMobile={toggleNavigationMobile} />
                </div>
                <div className={` ${isSearchBarVisible ? "block  inset-0 z-5" : "hidden"}`}>
                    <SearchBar isSearchBarVisible={isSearchBarVisible} toggleSearchBarVisible={toggleSearchBarVisible} />
                </div>
            </div>
        </>
    )
}